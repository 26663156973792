import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';



import { HeaderComponent } from './header.component';


@NgModule({
    imports: [
      CommonModule,
      IonicModule,
      ReactiveFormsModule,
    ],
    declarations: [HeaderComponent],
    exports: [HeaderComponent]
  })
  export class HeaderComponentModule {}