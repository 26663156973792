import { AdminDetailsService } from './../../services/admin-details.service';
import { ToastMessageService } from './../../services/toastMessage.service';
import { CommonService } from './../../services/common.service';
import { Auth } from 'aws-amplify';
import { Component, OnInit, ViewChild, } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment as ENV } from '../../../environments/environment';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public changePasswordForm: FormGroup;
  public checkPassword: boolean;
  constructor(
    private router: Router,
    public formBuilder: FormBuilder,
    private CommonService: CommonService,
    private toastMessageService: ToastMessageService,
    private adminDetailsService: AdminDetailsService,
  ) {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(ENV.REGEX.TEAM_PASSWORD),
      ])),
      newPassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(ENV.REGEX.TEAM_PASSWORD),
      ])),
      confirmPassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(ENV.REGEX.TEAM_PASSWORD),
      ])),
    });
    this.changePasswordForm.reset();
  }

  ngOnInit() { }


  openMenu() {
    $('.userMenu').addClass('active');
  }

  closeMenu() {
    $('.userMenu').removeClass('active');
  }

  logout() {
    Auth.signOut()
      .then(() => {
        this.adminDetailsService.resetLoginCredentials();
        this.closeMenu();
        this.router.navigate(['login'])
      })
      .catch(error => console.log(error))
  }

  dashboardPage() {
    this.router.navigate(['dashboard']);
  }


  addHospital() {
    this.CommonService.toggleAddHospitalPopup(true)
  }

  addTeamFormShow() {
    $('.addTeam, .overlay').addClass('active');
    $('#addTeamLogo').val(null);
    $('#addTeamName').focus();
  }

  opeChangePasswordForm() {
    $('.changePasswordForm').addClass('active');
    $('.overlay').addClass('active');
    $('#oldPassword').focus();
  }

  closeChangePasswordForm() {
    $('.changePasswordForm').removeClass('active');
    $('.overlay').removeClass('active');
    this.changePasswordForm.reset();
    this.checkPassword = false;
  }

  checkNewPassword() {
    this.changePasswordForm.controls["newPassword"].value === this.changePasswordForm.controls["confirmPassword"].value ? this.checkPassword = true : this.checkPassword = false;
  }

  checkValidation(formValue) {
    if (formValue.valid && this.checkPassword === true) {
      return false;
    } else {
      return true;
    }
  }

  submitChangePasswordForm() {
    let oldPassword = this.changePasswordForm.controls["oldPassword"].value;
    let newPassword = this.changePasswordForm.controls["newPassword"].value;
    Auth.currentAuthenticatedUser()
      .then(user => {
        Auth.changePassword(user, oldPassword, newPassword)
          .then(() => {
            Auth.updateUserAttributes( user, {
              "custom:userPassword": newPassword
            })
           .then( ()=> {
              oldPassword = '';
              newPassword = '';
              Auth.signOut()
              .then(() => {
                this.changePasswordForm.reset();
                $('.changePasswordForm, .overlay').removeClass('active');
                this.checkPassword = false;
                this.router.navigate(['login']);
              });
            })
          })
          .catch( () => {
            oldPassword = '';
            newPassword = '';
            this.toastMessageService.displayMessage('You have entered wrong password')
            this.changePasswordForm.reset();
            this.checkPassword = false;
            $('#oldPassword').focus();
          })
      })
      .catch(err => console.log(err));
  }


}
